<script>

export default {
    setup() {
        const i18nTitle = ref('')
        provide('i18nTitle', i18nTitle)
        const route = useRoute()

        const displayedTitle = computed(() => {
            return i18nTitle.value || route.meta.title
        })

        return { displayedTitle }
    }
}
</script>
<template>
    <AuthLayout>
        <template #toolbar-title>
          {{ displayedTitle }}
        </template>
        <slot />
    </AuthLayout>
</template>